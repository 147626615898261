.hashtag {
    cursor: pointer;
    display:inline-block;
    transition: transform 0.2s;
}

.hashtag:hover, .hashtag:hover span {
    transform: translateY(-1px);
}
  
.hashtag:active, .hashtag:active span {
    transform: translateY(1px);
}
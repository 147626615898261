.calendar-container {
  display: flex;
  transition: transform 0.3s ease;
  scrollbar-width: 0;
}

.calendar-container::-webkit-scrollbar {
  display: none;
}

.date-selected {
  outline-color: #0074D9;
  color: #707DFC;
}